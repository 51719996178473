<template>
  <el-dialog
    v-model="dialogVisible"
    :title="ruleForm.id ? '编辑缴费项目' : '添加缴费项目'"
    width="30%"
  >
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      status-icon
    >
      <el-form-item label="缴费项目名称" prop="name">
        <el-input v-model="ruleForm.name" />
      </el-form-item>
      <el-form-item label="执行编码" prop="number">
        <el-input v-model="ruleForm.number" />
      </el-form-item>
      <el-form-item label="操作人员" prop="user_ids_show">
        <el-select
          v-model="ruleForm.user_ids_show"
          multiple
          placeholder="选择操作人员"
          style="width: 240px"
        >
          <el-option
            v-for="item in operator"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="resetForm(ruleFormRef)"> 取消</el-button>
        <el-button type="primary" @click="submitForm(ruleFormRef)"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { userallListAPI, paymentItemsAddAPI, itemSaveAPI } from '@/api/project'
import { useVModel } from '@vueuse/core'
import { ElMessage } from 'element-plus'
const props = defineProps({
  modelValue: {
    // 接收 v-model
    type: Boolean,
    required: true
  },
  onProjectList: {
    type: Function
  },
  itemObj: {
    type: Object
  }
})
const emits = defineEmits(['update:modelValue']) // 注册 v-model 事件
const dialogVisible = useVModel(props, 'modelValue', emits) // 得到一个响应式的数据，可以直接修改

const ruleFormRef = ref(null)
const ruleForm = ref({
  name: '',
  number: '',
  pay_type: '',
  user_ids: '',
  user_ids_show: []
})
const rules = {
  name: [
    {
      required: true,
      message: '请输入缴费项目名称',
      trigger: 'blur'
    }
  ],
  number: [
    {
      required: true,
      message: '请输入执行编码',
      trigger: 'blur'
    }
  ],

  user_ids_show: [
    {
      required: true,
      message: '请选择操作人员',
      trigger: 'blur'
    }
  ]
}

// 修改
watch(
  () => props.itemObj,
  (val) => {
    console.log(val)
    ruleForm.value = val
  },
  {
    deep: true
  }
)

watch(dialogVisible, (val) => {
  if (!val) {
    ruleForm.value = {
      name: '',
      number: '',
      pay_type: '',
      user_ids: '',
      user_ids_show: []
    }
  }
})

// 人员选择
const operator = ref([])
const onUserallList = async () => {
  const data = await userallListAPI()
  operator.value = data
  console.log(data)
}
onUserallList()

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      ruleForm.value.user_ids = ruleForm.value.user_ids_show.join()

      if (ruleForm.value.id) {
        delete ruleForm.value.user_name
        delete ruleForm.value.user_ids_show
        await itemSaveAPI(ruleForm.value)
        ElMessage.success('缴费修改成功')
      } else {
        await paymentItemsAddAPI(ruleForm.value)
        ElMessage.success('缴费添加成功')
      }
      props.onProjectList()
      dialogVisible.value = false
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  dialogVisible.value = false
  formEl.resetFields()
}
</script>

<style></style>
