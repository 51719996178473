import request from '@/utils/request'

// 项目列表
export const projectListAPI = (params) => {
  return request({
    url: '/itemList',
    method: 'GET',
    params
  })
}

// 参与人员列表
export const userallListAPI = (params) => {
  return request({
    url: '/userallList',
    method: 'GET',
    params
  })
}

// 添加
export const paymentItemsAddAPI = (data) => {
  return request({
    url: '/addItem',
    method: 'POST',
    data
  })
}

// 删除
export const itemDelAPI = (params) => {
  return request({
    url: '/itemDel',
    method: 'GET',
    params
  })
}

// 修改
export const itemSaveAPI = (data) => {
  return request({
    url: '/itemSave',
    method: 'POST',
    data
  })
}
