<template>
  <div>
    <el-row>
      <el-col>
        <el-button type="primary" @click="onAddProject">
          <el-icon><CirclePlus /></el-icon> &nbsp; 新增缴费项目
        </el-button>
      </el-col>
    </el-row>

    <!-- 表格数据 -->
    <el-table
      :data="progectList"
      border
      style="width: 100%; margin-top: 20px"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column prop="name" label="缴费项目" />
      <el-table-column prop="number" label="执行编码" />
      <el-table-column prop="user_name" label="操作人员" />
      <el-table-column label="操作">
        <template #default="{ row }">
          <el-button type="primary" size="small" @click="editBtn(row)"
            >修改</el-button
          >

          <el-popconfirm
            title="是否删除该缴费?"
            confirm-button-text="确认"
            cancel-button-text="取消"
            @confirm="deleteBtn(row.id)"
          >
            <template #reference>
              <el-button type="danger" size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="search.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="search.total"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <!-- 新增 -->
    <add-project
      v-model="dialogVisible"
      :onProjectList="onProjectList"
      :itemObj="itemObj"
    ></add-project>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { projectListAPI, itemDelAPI } from '@/api/project'
import addProject from './components/addProject.vue'
import { ElMessage } from 'element-plus'
/** 表格 S */

const search = ref({
  page: 1,
  total: 0,
  limit: 10
})

const progectList = ref([])
const onProjectList = async () => {
  const { data, total } = await projectListAPI(search.value)
  progectList.value = data
  search.value.total = total
}
onProjectList()

// 修改
const itemObj = ref({})
const editBtn = (row) => {
  dialogVisible.value = true
  itemObj.value = row
  itemObj.value.user_ids_show = row.user_ids.split()
}

// 删除
const deleteBtn = async (id) => {
  await itemDelAPI({ id })
  ElMessage.success('项目删除成功 。 ')
  onProjectList()
}

/** 表格 E */

/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  console.log(val)
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  console.log(val)
}

/**
 *  分页 E
 */

/** 弹窗 S */
const dialogVisible = ref(false)
const onAddProject = () => {
  dialogVisible.value = true
}
/** 弹窗 E */
</script>

<style lang="scss" scoped></style>
